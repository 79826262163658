/*
Copyright (C) 2020 Cryptium Corporation. All rights reserved.
*/
/* eslint-disable no-console */

const nameRegex = new RegExp('[A-Z0-9]', 'i'); // simple rule: just has to include a letter or digit; TODO: allow all unicode letters
const emailRegex = new RegExp('^[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,}$', 'i');
const phoneRegex = new RegExp('^[0-9#*()+,. -]+$', 'i');
const compactRegex = new RegExp('[^0-9a-zA-Z]', 'g'); // TODO: allow all unicode letters
const requestMethodRegex = new RegExp('^DELETE|GET|HEAD|OPTIONS|PATCH|POST|PUT|\\*$', 'i');
const requestDomainRegex = new RegExp('^[A-Z0-9.-]+\\.[A-Z]{2,}$', 'i');
const requestPathRegex = new RegExp('^/[^?#]*$', 'i');
const sshAliasPrefixRegex = new RegExp('[A-Z]', 'i'); // simple rule: only letters; NOTE: server will add digits at the end, and final value becomes a Linux username
const volumeAliasRegex = new RegExp('^[A-Z0-9](?:[-]?[A-Z0-9]+)*$', 'i'); // letters, digits, and hyphens are allowed; must start and end with letter or digit; not more than one consecutive hyphen is allowed

function isValidName(v) {
    return (typeof v === 'string' && v.length > 0 && nameRegex.test(v));
}
function isValidEmail(v) {
    return (typeof v === 'string' && v.length > 0 && v.length <= 256 && emailRegex.test(v));
}
function isValidPhone(v) {
    return (typeof v === 'string' && v.length > 0 && v.length <= 64 && phoneRegex.test(v));
}
function isValidURL(v) {
    if (typeof v !== 'string' || v.length === 0) {
        return false;
    }
    try {
        const u = new URL(v);
        if (!['http:', 'https:'].includes(u.protocol)) {
            return false;
        }
        if (typeof u.host !== 'string' || u.host.length === 0) {
            return false;
        }
        return true;
    } catch (err) {
        return false;
    }
}
function isValidSshAliasPrefix(v) {
    return (typeof v === 'string' && v.length > 0 && v.length <= 12 && sshAliasPrefixRegex.test(v));
}
function isValidVolumeAlias(v) {
    return (typeof v === 'string' && v.length > 0 && v.length <= 192 && volumeAliasRegex.test(v));
}

/**
 * Returns a version of the name consisting only of digits and
 * lowercase letters.
 */
function compact(name) {
    return name.toLowerCase().replace(compactRegex, '');
}

function isValidRequestMethod(v) {
    return (typeof v === 'string' && v.length > 0 && requestMethodRegex.test(v));
}

function isValidRequestDomain(v) {
    return (typeof v === 'string' && v.length > 0 && requestDomainRegex.test(v));
}

function isValidRequestPath(v) {
    return (typeof v === 'string' && v.length > 0 && requestPathRegex.test(v));
}

export {
    isValidName, isValidEmail, isValidPhone, compact,
    isValidRequestMethod, isValidRequestDomain, isValidRequestPath,
    isValidURL,
    isValidSshAliasPrefix,
    isValidVolumeAlias,
};
